import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Header from "../components/header"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Header />
    <div
      style={{
        marginTop: `var(--space-5)`,
      }}
    >
      <p style={{fontWeight:`bold`}}>
        Herzlich Willkommen! Die INTRA International Trading GmbH beschäftigt
        sich seit 2005 mit dem An- und Verkauf mobiler Krane aller namhaften
        Hersteller. Wir agieren national und international. Unser Zubehörhandel
        rundet unser Angebot ab. Vertrauen Sie auf unser weitreichendes
        Know-How.
      </p>
      <StaticImage
        src="../images/INTRA-CRANES.jpg"
        loading="lazy"
        placeholder="tracedSVG"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Foto eines Kranes INTRA Cranes"
      />
    </div>
  </Layout>
)

export default IndexPage
